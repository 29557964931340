import type { DraftContent } from 'ricos-editor';
import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type {
  CursorPaging,
  Query,
} from '@wix/ambassador-feed-v1-feed-item/types';

import { ReactionMode } from 'api/feed/types';

import { selectPinnedItem } from 'store/feed/selectors';
import type { IRootStore } from 'store/index';
import * as feed from 'store/feed';

import type { ICommentsVM } from './comments.vm';

export function FeedVM(
  params: ControllerParams,
  comments: ICommentsVM,
  store: IRootStore,
) {
  return {
    feed$: {
      remove,
      fetch,
      create,
      update,
      react,
      unreact,
      pin,
      unpin,
      subscribe,
      unsubscribe,
      updateDraft,
      filter,
      get,
    },
  };

  async function create(groupId: string, content: string, topics: string[]) {
    const feedItem = await store
      .dispatch(
        feed.thunks.create({
          groupId,
          content,
          topics,
        }),
      )
      .unwrap();

    await comments._.comments.fetch([feedItem]);

    return feedItem;
  }

  function update(
    groupId: string,
    feedItemId: string,
    content: string,
    topics: string[],
  ) {
    store.dispatch(
      feed.thunks.update({
        groupId,
        feedItemId,
        content,
        topics,
      }),
    );
  }

  async function filter(groupId: string, query?: Query, withComments = true) {
    const { data } = await store
      .dispatch(
        feed.thunks.fetch({
          groupId,
          query,
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }

  async function get(groupId: string, feedItemId: string, withComments = true) {
    const feedItem = await store
      .dispatch(
        feed.thunks.get({
          groupId,
          feedItemId,
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch([feedItem]);
    }

    return feedItem;
  }

  async function fetch(
    groupId: string,
    paging: CursorPaging = {},
    withComments = true,
  ) {
    const { cursor, limit = 5 } = paging;

    const { data } = await store
      .dispatch(
        feed.thunks.fetch({
          groupId,
          cursor: {
            cursor,
            limit,
          },
        }),
      )
      .unwrap();

    if (withComments) {
      await comments._.comments.fetch(data.items);
    }

    return data;
  }

  function subscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.subscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  function unsubscribe(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unsubscribe({
        feedItemId,
        groupId,
      }),
    );
  }

  async function pin(groupId: string, feedItemId: string) {
    const pinnedItem = selectPinnedItem(store.getState());

    if (pinnedItem) {
      await unpin(groupId, pinnedItem.feedItemId as string);
    }

    return store.dispatch(
      feed.thunks.pin({
        feedItemId,
        groupId,
      }),
    );
  }

  function unpin(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.unpin({
        feedItemId,
        groupId,
      }),
    );
  }

  function react(
    groupId: string,
    feedItemId: string,
    code: string,
    mode?: ReactionMode,
  ) {
    return store.dispatch(
      feed.thunks.react({
        mode,
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function unreact(groupId: string, feedItemId: string, code: string) {
    return store.dispatch(
      feed.thunks.unreact({
        code,
        groupId,
        feedItemId,
      }),
    );
  }

  function remove(groupId: string, feedItemId: string) {
    return store.dispatch(
      feed.thunks.remove({
        groupId,
        feedItemId,
      }),
    );
  }

  function updateDraft(draftContent?: DraftContent) {
    return store.dispatch(feed.actions.updateDraft({ draftContent }));
  }
}

export type IFeedVM = Omit<ReturnType<typeof FeedVM>, '$'>;
