import uuidv5 from 'uuid/v5';

import { ContentType } from '@wix/ambassador-feed-v1-feed-item/types';
import { WIX_STATIC, WIX_STATIC_VIDEO_BASE_URL } from 'config/constants';
import { Entity } from '../../content/Entity';
import {
  IProGalleryItem,
  IProGalleryMetadata,
  ProGalleryTypes,
} from './ProGalleryItem';
import { RawDraftEntity } from '../../types';
import { PluginTypes } from '../pluginTypes';
import { ImageContent } from '../types/ImageContent';
import {
  GalleryContent,
  GalleryMedia,
  GalleryVideo,
} from '../types/GalleryContent';
import { VideoContent } from '../types/VideoContent';
import { ExternalVideoContent } from '../types/ExternalVideoContent';
import { GiphyContent } from '../types/GiphyContent';
import { ContentConverter } from '../../content/ContentConverter';
import { ProGalleryHTML } from './ProGalleryHTML';
import { IProGalleryMedia, ProGalleryMediaMetadata } from './ProGalleryMedia';
import {
  GalleryPoster,
  ProGalleryVideo,
  ProGalleryVideoMetadata,
} from './ProGalleryVideo';

export class ProGalleryConverter {
  constructor(private readonly description?: string) {}

  private proGalleryItems!: IProGalleryItem[];
  getRawDraftEntities(entity: Entity): RawDraftEntity<any>[] {
    try {
      const {
        body: { content, contentType },
      } = entity;
      if (contentType === ContentType.DRAFTJS) {
        const converter = new ContentConverter();
        const draftJs = converter.parseDraftJsFromString(content!);
        return Object.values(draftJs!.entityMap);
      }
    } catch (e) {
      console.log('Error in [ProGalleryConverter.getRawDraftEntities]', e);
    }
    return [];
  }

  getProGalleryItems(rawEntities: RawDraftEntity<any>[]): IProGalleryItem[] {
    this.proGalleryItems = [];
    try {
      for (const raw of rawEntities) {
        this.proGalleryItems = this.proGalleryItems.concat(
          this.convertRawEntity(raw) || [],
        );
      }
    } catch (e) {
      console.log('Error in [ProGalleryConverter.getProGalleryItems]', e);
    }
    return this.proGalleryItems;
  }

  private convert(entity: Entity) {
    const entities = this.getRawDraftEntities(entity);
    return this.getProGalleryItems(entities);
  }

  static convertContentEntity(
    entity: Entity,
    description?: string,
  ): IProGalleryItem[] {
    const converter = new ProGalleryConverter(description);
    return converter.convert(entity);
  }

  private readonly convertRawEntity = (raw: RawDraftEntity<any>) => {
    const pluginConverter =
      // @ts-expect-error
      this.pluginConverters[raw.type] || this.undefinedTypeConverter;
    return pluginConverter(raw);
  };

  private readonly undefinedTypeConverter = (
    raw: RawDraftEntity<never>,
  ): undefined => {
    return;
  };

  private readonly convertImage = (
    raw: RawDraftEntity<ImageContent>,
  ): IProGalleryMedia => {
    const {
      data: {
        src: { id: itemId, file_name, height, width },
      },
    } = raw;
    const metaData: ProGalleryMediaMetadata = {
      description: this.description || '',
      height,
      link: { url: '' },
      title: file_name,
      type: ProGalleryTypes.IMAGE,
      width,
    };
    return {
      itemId: itemId || uuidv5(file_name, uuidv5.URL),
      mediaUrl: this.imageUrl(file_name),
      metaData,
    };
  };

  private readonly convertGiphy = (
    raw: RawDraftEntity<GiphyContent>,
  ): IProGalleryMedia => {
    const {
      data: {
        gif: { originalUrl, width, height },
      },
    } = raw;

    const metaData: ProGalleryMediaMetadata = {
      description: this.description || '',
      height,
      link: { url: '' },
      title: originalUrl,
      type: ProGalleryTypes.IMAGE,
      width,
    };
    return {
      itemId: uuidv5(originalUrl, uuidv5.URL),
      mediaUrl: originalUrl,
      metaData,
    };
  };

  private readonly convertGallery = (
    raw: RawDraftEntity<GalleryContent>,
  ): IProGalleryItem[] => {
    const {
      data: { items },
    } = raw;
    return items.map((item: GalleryMedia | GalleryVideo) => {
      const {
        metadata: { type },
      } = item;
      if (type === 'video') {
        return this.convertGalleryVideo(item as GalleryVideo);
      }
      return this.convertGalleryImage(item);
    });
  };

  private convertGalleryImage(item: GalleryMedia): IProGalleryMedia {
    const {
      itemId,
      metadata: { width, height },
      url,
    } = item;
    const metaData: ProGalleryMediaMetadata = {
      description: this.description || '',
      focalPoint: [],
      height,
      width,
      type: ProGalleryTypes.IMAGE,
      link: { url: '' }, // TODO
      title: '', // TODO
    };
    return { itemId, mediaUrl: this.imageUrl(url), metaData };
  }

  private convertGalleryVideo(item: GalleryVideo): ProGalleryVideo {
    const {
      itemId,
      metadata: { width, height, poster },
      url,
    } = item;
    const metaData: ProGalleryVideoMetadata = this.getVideoMetaData(
      width,
      height,
      url,
      { url: this.imageUrl(poster) },
    );

    return { itemId, mediaUrl: this.videoUrl(url), metaData };
  }

  private readonly convertVideo = (
    raw: RawDraftEntity<VideoContent | ExternalVideoContent>,
  ): IProGalleryItem => {
    const metadata = (raw.data as ExternalVideoContent).metadata;
    if (metadata && metadata.oembed) {
      return this.convertExternalVideo(
        raw as RawDraftEntity<ExternalVideoContent>,
      );
    }
    const {
      data: {
        src: {
          pathname,
          thumbnail: { width, height, pathname: poster },
        },
      },
    } = raw as RawDraftEntity<VideoContent>;
    const videoUrl = this.videoUrl(pathname);
    const posterUrl = this.imageUrl(poster);
    const metaData: ProGalleryVideoMetadata = this.getVideoMetaData(
      width,
      height,
      videoUrl,
      { url: posterUrl },
    );
    const videoItem: ProGalleryVideo = {
      itemId: uuidv5(videoUrl, uuidv5.URL),
      mediaUrl: videoUrl,
      metaData,
    };
    return videoItem;
  };

  private getVideoMetaData(
    width: number,
    height: number,
    videoUrl: string,
    poster: GalleryPoster,
    title?: string,
  ): ProGalleryVideoMetadata {
    return {
      description: this.description || '',
      width,
      height,
      videoUrl,
      poster,
      focalPoint: [],
      link: { url: '' }, // TODO
      title: title || '',
      type: ProGalleryTypes.VIDEO,
    };
  }

  pluginConverters = {
    [PluginTypes.Image]: this.convertImage,
    [PluginTypes.Video]: this.convertVideo,
    [PluginTypes.Gallery]: this.convertGallery,
    [PluginTypes.Giphy]: this.convertGiphy,
  };

  private convertExternalVideo(
    raw: RawDraftEntity<ExternalVideoContent>,
  ): ProGalleryHTML | ProGalleryVideo {
    const {
      data: {
        metadata: {
          oembed: {
            height,
            width,
            html,
            title,
            thumbnail_height,
            thumbnail_url,
            thumbnail_width,
            video_url,
          },
        },
        src,
      },
    } = raw;

    if (!html && video_url) {
      const metaData: ProGalleryVideoMetadata = this.getVideoMetaData(
        width || thumbnail_width,
        height || thumbnail_height,
        video_url,
        {
          url:
            thumbnail_url ||
            'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
          width: thumbnail_width || width,
          height: thumbnail_height || height,
        },
        title,
      );
      const videoItem: ProGalleryVideo = {
        itemId: uuidv5(video_url, uuidv5.URL),
        mediaUrl: video_url,
        metaData,
      };
      return videoItem;
    }
    const metaData: IProGalleryMetadata = {
      description: this.description || '',
      height: height || thumbnail_height,
      width: width || thumbnail_width,
      link: { url: '' }, // TODO: link to feed item
      title,
      type: ProGalleryTypes.TEXT,
    };
    return { itemId: uuidv5(src, uuidv5.URL), html, metaData };
  }

  /**
   *  replaces substring that starts with
   *  - /
   *  - /media
   *  - /video
   *  - /media/video
   * @param  mediaUrl
   */
  private mediaIdFromUrl(mediaUrl: string): string {
    return mediaUrl.replace(/(^\/*(media*\/*)?(video*\/*)?)/g, '');
  }

  private videoUrl(fileUrl: string) {
    return `${WIX_STATIC_VIDEO_BASE_URL}/${this.mediaIdFromUrl(fileUrl)}`;
  }

  private imageUrl(fileUrl: string) {
    return `${WIX_STATIC}/media/${this.mediaIdFromUrl(fileUrl)}`;
  }
}
