import { pick } from 'lodash';

import type { IUser } from '@wix/yoshi-flow-editor';
import {
  followMember,
  listMyMemberFollowing,
  unfollowMember,
} from '@wix/ambassador-members-v2-follow/http';
import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Set as WixMembersFieldSet } from '@wix/ambassador-members-v1-member/types';
import { getSettings } from '@wix/ambassador-social-groups-v2-groups-app-settings/http';

import { mapMember } from 'api/members/selectors';

import { createAsyncThunk } from '../utils';
import type { IApplicationUser } from './types';

export const fetchSettings = createAsyncThunk(
  'application:fetchSettings',
  async (_: void, thunkAPI) => {
    const response = await thunkAPI.extra.httpClient.request(getSettings({}));

    return response.data.settings;
  },
);

export const follow = createAsyncThunk(
  'application:follow',
  async (memberId: string, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(followMember({ memberId }));

    return memberId;
  },
);

export const unfollow = createAsyncThunk(
  'application:unfollow',
  async (memberId: string, thunkAPI) => {
    await thunkAPI.extra.httpClient.request(unfollowMember({ memberId }));

    return memberId;
  },
);

export const login = createAsyncThunk(
  'application:login',
  async (_, thunkAPI) => {
    const { controllerConfig } = thunkAPI.extra;

    try {
      await controllerConfig.wixCodeApi.user.promptLogin({ modal: true });

      return thunkAPI.dispatch(fetchCurrentUserProfile());
    } catch (err) {
      return thunkAPI.rejectWithValue(false);
    }
  },
  { hideErrorMessage: true },
);

export const fetchCurrentUserProfile = createAsyncThunk<
  Partial<IApplicationUser>,
  void,
  { pendingMeta: IUser }
>(
  'application:currentUserProfile',
  async function (_, thunkAPI) {
    const flowApi = thunkAPI.extra;
    const { wixCodeApi } = flowApi.controllerConfig;

    const wixUser = {
      ...wixCodeApi.user.currentUser,
      instance: wixCodeApi.site.getAppToken?.(
        flowApi.environment.appDefinitionId,
      ),
    };

    const user = pick(
      {
        ...wixCodeApi.user.currentUser,
        instance: wixCodeApi.site.getAppToken?.(
          flowApi.environment.appDefinitionId,
        ),
      },
      'loggedIn',
      'role',
      'instance',
    );

    if (!wixUser.loggedIn) {
      return {
        following: [],
        siteMemberId: wixUser.id,
        ...user,
      };
    }

    const [response, following] = await Promise.all([
      flowApi.httpClient.request(
        getMyMember({
          fieldsets: [WixMembersFieldSet.FULL],
        }),
      ),
      flowApi.httpClient.request(listMyMemberFollowing({})).catch((error) => {
        flowApi.errorMonitor.captureException(error);

        return { data: { memberIds: [] } };
      }),
    ]);

    if (!response.data.member) {
      throw new Error('Current user profile not found');
    }

    return {
      ...user,
      ...mapMember(response.data.member),
      following: following.data.memberIds || [],
    } as IApplicationUser;
  },
  {
    getPendingMeta(base, api) {
      return api.extra.controllerConfig.wixCodeApi.user.currentUser;
    },
  },
);
