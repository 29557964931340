import * as api from 'api/topics/topics.api';

import { createAsyncThunk } from '../utils';

import { ICreateParams } from './types';

export const fetch = createAsyncThunk(
  'topics:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(api.list(groupId));

    return data;
  },
);

export const create = createAsyncThunk(
  'topics:create',
  async function (params: ICreateParams, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      api.create(params.groupId, params.label),
    );

    return data;
  },
  {
    formatError: (t, params) =>
      t('groups-web.toast.error.topics.create', {
        name: params.label,
      }),
  },
);
