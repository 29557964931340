import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk as reduxCreateAsyncThunk,
} from '@reduxjs/toolkit';

import { HttpClient, TFunction } from '@wix/yoshi-flow-editor';

import { applicationSlice } from './application/slice';
import type { AsyncThunkConfig } from './types';

type ThunkOptions<Arg> = AsyncThunkOptions<Arg, AsyncThunkConfig> & {
  hideErrorMessage?: boolean | ((error: unknown) => boolean);

  formatError?(t: TFunction, arg: Arg): string;
};

type ReduxAsyncThunkConfig = {
  pendingMeta?: unknown;
  serializedErrorType?: unknown;
};

export function createAsyncThunk<
  Returned,
  Arg = void,
  Config extends ReduxAsyncThunkConfig = {},
>(
  prefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, Arg, AsyncThunkConfig>,
  options: ThunkOptions<Arg> = {},
) {
  const create = reduxCreateAsyncThunk.withTypes<AsyncThunkConfig>();

  return create(
    prefix,
    async function (arg, thunkApi) {
      const { dispatch, extra: flowApi } = thunkApi;
      const { t } = flowApi.translations;

      const {
        hideErrorMessage = false,
        formatError = defaultErrorFormatter(prefix),
      } = options;

      try {
        const result = await payloadCreator(arg, thunkApi);

        return result;
      } catch (error) {
        const hideToast =
          typeof hideErrorMessage === 'function'
            ? hideErrorMessage(error)
            : hideErrorMessage;

        if (!hideToast) {
          dispatch(
            applicationSlice.actions.showToast({
              type: 'error',
              message: formatError(t as TFunction, arg),
              description: HttpClient.isHttpError(error)
                ? error.message
                : undefined,
            }),
          );
        }

        flowApi.errorMonitor.captureException(error as Error);

        if (HttpClient.isHttpError(error)) {
          console.error(prefix, error.requestId, error.response);
        }

        console.error(prefix, error);

        throw error;
      }
    },
    options,
  ) as AsyncThunk<Returned, Arg, Config & AsyncThunkConfig>;
}

export function defaultErrorFormatter(name: string) {
  const key = name.replace(/:/g, '.');

  return (t: TFunction) => t(`groups-web.toast.error.${key}`);
}

export function is404(error: unknown) {
  if (!HttpClient.isHttpError(error)) {
    return false;
  }

  return error?.response?.status === 404;
}
