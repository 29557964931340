import type { RawParams, UIRouter } from '@uirouter/core';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type { ICentralFeedListResponse } from 'api/feed/central-feed/types';
import { EGroupPartition, SortOrder } from 'api/groups/types';

import { selectStateDeclarations } from 'store/selectors';
import type { IApplicationUser } from 'store/application';
import type { IRootStore } from 'store/index';

import type { IVM } from '../../vm/types';

import { GroupsRequestSort, LayoutType } from 'settings/consts';

import settingsParams from './settingsParams';

export function setupRouter(
  params: ControllerParams,
  vm: IVM,
  router: UIRouter,
  store: IRootStore,
) {
  const { isSSR, isMobile } = params.flowAPI.environment;

  const { settings } = params.flowAPI;
  const sortBy = settings.get(settingsParams.sortBy);

  const states = selectStateDeclarations(store.getState());

  router.stateRegistry.deregister('groups');

  router.stateRegistry.register({
    ...states.groups,
    resolve: [
      {
        token: 'isLoggedIn',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          return user.loggedIn;
        },
      },
      {
        token: 'layout',
        resolveFn() {
          return settings.get(settingsParams.groupListLayout);
        },
      },
      {
        token: 'useFeed',
        deps: ['layout'],
        resolveFn(layout: LayoutType) {
          return layout === LayoutType.sideBar;
        },
      },
      {
        token: 'commentsApi',
        deps: ['useFeed'],
        async resolveFn(useFeed: boolean) {
          if (!isSSR && useFeed) {
            await vm._.comments.init();
          }
        },
      },
      {
        token: 'application-settings',
        resolveFn() {
          return vm.application$.fetchSettings();
        },
      },
      {
        token: 'group-requests',
        deps: ['isLoggedIn'],
        resolveFn(isLoggedIn: boolean) {
          if (!isLoggedIn) {
            return;
          }

          return vm.groupRequests$.fetch({
            limit: 5,
            offset: 0,
          });
        },
      },
      {
        token: 'feed',
        deps: ['useFeed'],
        async resolveFn(useFeed: boolean) {
          if (useFeed) {
            return vm.centralFeed$.fetch(undefined, false);
          }
        },
      },
      {
        token: 'comments',
        deps: ['feed', 'commentsApi'],
        async resolveFn(feed?: ICentralFeedListResponse) {
          if (!isSSR && feed) {
            await vm._.comments.fetch(feed.items);
          }
        },
      },
    ],
    async onEnter(transition) {
      const useFeed: boolean = transition.injector().get('useFeed');

      if (!isSSR && useFeed) {
        await transition.injector().getAsync('commentsApi');
        vm._.comments.bind();
      }
    },
  });

  router.stateRegistry.register({
    ...states['groups.list'],
    params: {
      ...states['groups.list'].params,
      sort: {
        value: sortBy,
        squash: true,
      },
    },
    resolve: [
      {
        token: 'groups',
        deps: ['$stateParams', 'layout'],
        async resolveFn(params: RawParams, layout: LayoutType) {
          switch (layout) {
            case LayoutType.list:
              return vm.groups$.query({
                ...params,
                namespace: params.partition,
                partition: params.partition,
              });

            case LayoutType.grid:
              return vm.groups$.query({
                ...params,
                limit: 6,
                namespace: params.partition,
                partition: params.partition,
              });

            case LayoutType.sideBar:
            default:
              return isMobile
                ? Promise.resolve([])
                : Promise.all([
                    vm.groups$.query({
                      namespace: EGroupPartition.JOINED,
                      partition: EGroupPartition.JOINED,
                      limit: 5,
                    }),
                    vm.groups$.query({
                      namespace: EGroupPartition.SUGGESTED,
                      partition: EGroupPartition.SUGGESTED,
                      limit: 10,
                    }),
                  ]);
          }
        },
      },
    ],
  });
}
