import {
  RequestStatus,
  OwnershipFilter,
} from '@wix/ambassador-social-groups-v2-group-request/types';
import type { Group } from '@wix/ambassador-social-groups-v2-group/types';

import * as api from 'api/group-requests/group-requests.api';
import { selectIsSiteAdmin } from 'store/application/selectors';

import { createAsyncThunk } from '../utils';

import type { IFetchParams, IDeclineParams } from './types';

export const create = createAsyncThunk(
  'group-requests:create',
  async function (group: Group, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(api.create(group));

    return data;
  },
  {
    formatError(t, group) {
      return t('groups-web.toast.error.group-requests.create', {
        name: group.name,
      });
    },
  },
);

export const cancel = createAsyncThunk(
  'group-requests:cancel',
  async function (id: string, thunkAPI) {
    return thunkAPI.extra.httpClient.request(api.remove(id));
  },
);

export const decline = createAsyncThunk(
  'group-requests:decline',
  async function (params: IDeclineParams, thunkAPI) {
    return thunkAPI.extra.httpClient.request(
      api.decline(params.id, params.reason),
    );
  },
);

export const approve = createAsyncThunk(
  'group-requests:approve',
  async function (id: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(api.approve(id));

    return data.group;
  },
);

export const fetch = createAsyncThunk(
  'group-requests:fetch',
  async function (params: IFetchParams, thunkAPI) {
    const isSiteAdmin = selectIsSiteAdmin(thunkAPI.getState());

    const { data } = await thunkAPI.extra.httpClient.request(
      api.list({
        ownershipFilter: isSiteAdmin
          ? OwnershipFilter.ALL
          : OwnershipFilter.CURRENT_MEMBER,
        query: {
          filter: {
            status: RequestStatus.PENDING,
          },
          paging: {
            limit: params.limit,
            offset: params.offset,
          },
        },
      }),
    );

    return data;
  },
);
