import {
  LocationConfig,
  LocationServices,
  MemoryLocationConfig,
  MemoryLocationService,
  Rejection,
  servicesPlugin,
  UIRouter,
} from '@uirouter/core';

import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { PushLocationService, WixLocationConfig } from './core';
import { URIEncoded } from './paramTypes';

export function controllerPlugin(flowApi: ControllerFlowAPI) {
  return function (router: UIRouter) {
    const {
      environment,
      controllerConfig: { wixCodeApi },
    } = flowApi;

    let configuration: LocationConfig;
    let service: LocationServices;

    if (environment.isViewer) {
      configuration = new WixLocationConfig(wixCodeApi.location);
      service = new PushLocationService(router, wixCodeApi.location);
    } else {
      configuration = new MemoryLocationConfig();
      service = new MemoryLocationService(router);

      try {
        const params = JSON.parse(wixCodeApi.location.query.appSectionParams);
        if (params.state) {
          router.urlService.rules.initial(params);
        }
      } catch {}
    }

    router.locationConfig = configuration;
    router.locationService = service;

    router.plugin(servicesPlugin);
    router.stateService.defaultErrorHandler(transitionErrorHandler);

    router.urlService.config.type('URIEncoded', URIEncoded);
    router.urlService.deferIntercept();

    router.stateRegistry.decorator('resolvePolicy', (state) => {
      const { isSSR } = environment;
      const { resolvePolicy } = state;

      return {
        when: resolvePolicy?.when || 'EAGER',
        async: resolvePolicy?.async || isSSR ? 'WAIT' : 'NOWAIT',
      };
    });

    return {
      name: 'WixUIRouterControllerPlugin',
      dispose(router: UIRouter) {
        router.dispose(configuration);
        router.dispose(service);
      },
    };

    function transitionErrorHandler(error: Rejection) {
      flowApi.errorMonitor.captureMessage(error.message, {
        contexts: {
          transitionErrorDetails: error.detail,
        },
        tags: {
          reasonMessage: error?.detail?.message,
          reasonStatus: error?.detail?.status,
        },
      });
      console.error(error);
    }
  };
}
